import { EyeAppeal } from '../../../types/EyeAppealType';

export const filterOptions = {
  sort: [
    { name: 'Sold Date', key: '' },
    { name: 'Highest Price', key: 'purchasePrice,desc' },
    { name: 'Lowest Price', key: 'purchasePrice,asc' },
  ],
  auctionTypes: [
    { name: 'Fixed Price', key: 'FIXED' },
    { name: 'Weekly Sunday Auction', key: 'WEEKLY,FLASH,MONTHLY' },
    { name: 'Premier Auction', key: 'PREMIER' },
  ],
  category: [
    { name: 'Baseball' },
    { name: 'Basketball' },
    { name: 'Football' },
    { name: 'Hockey' },
    { name: 'Golf' },
    { name: 'Boxing & MMA' },
    { name: 'Soccer' },
    { name: 'Tennis' },
    { name: 'Racing' },
    { name: 'Misc Sports' },
    { name: 'Non-Sport' },
    { name: 'Yu-Gi-Oh!' },
    { name: 'Magic The Gathering' },
    { name: 'Pokémon' },
    { name: 'Collectible Card Games' },
  ],
  otherItems: [
    { name: 'Memorabilia' },
    { name: 'Photos' },
    { name: 'Comics' },
    { name: 'Tickets' },
    { name: 'Coins' },
    { name: 'Video Games' },
  ],
  eyeAppealGrade: [
    { name: 'Eye Appeal - A', key: EyeAppeal.PWCC_A },
    { name: 'Eye Appeal - E', key: EyeAppeal.PWCC_E },
    { name: 'Eye Appeal - S', key: EyeAppeal.PWCC_S },
  ],
  gradingService: [
    { name: 'PSA' },
    { name: 'SGC' },
    { name: 'BGS' },
    { name: 'BVG' },
    { name: 'BCCG' },
    { name: 'CGC' },
    { name: 'CSG' },
    { name: 'ungraded' },
  ],
};
